import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export default function CallTrackLink() {
  let data = {
    site: {
      siteMetadata: {
        calltracksClass: ''
      }
    },
    wp: {
      siteSettings: {
        siteSettings: {
          telephone: ''
        }
      }
    }
  }
  if (!process.env.STORYBOOK) {
    data = useStaticQuery(graphql`
      query CalltracksQuery {
        site {
          siteMetadata {
            calltracksClass
          }
        }
        wp {
          siteSettings {
            siteSettings {
              telephone
            }
          }
        }
      }
    `);
  }

  return (
    <a
    suppressHydrationWarning
      href={`tel:${data.wp.siteSettings.siteSettings.telephone.replace(
        /\s/g,
        ""
      )}`}
    >
      <span className={data.site.siteMetadata.calltracksClass} suppressHydrationWarning>
        {data.wp.siteSettings.siteSettings.telephone}
      </span>
    </a>
  );
}
