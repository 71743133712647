export function getGAClientId() {
  const cookie = {};
  if (typeof window === "undefined") {
    return '';
  }
  document.cookie.split(';').forEach(function(el) {
    const splitCookie = el.split('=');
    const key = splitCookie[0].trim();
    const value = splitCookie[1];
    cookie[key] = value;
  });
  if (cookie["_ga"]) {
    return cookie["_ga"].substring(6);
  }
  return '';
}
