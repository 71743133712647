import React, { createContext, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const ReviewsContext = createContext();

export const useReviews = () => {
  return useContext(ReviewsContext);
};

const ReviewsProvider = ({ children }) => {
  let reviewsData = {
    allWpReview: {
      edges: []
    }
  }
  if (!process.env.STORYBOOK) {
    reviewsData = useStaticQuery(graphql`
      query ReviewsQuery {
        allWpReview {
          edges {
            node {
              title
              customerReviews {
                reviewDate
                reviewProduct {
                  ... on WpProduct {
                    id
                  }
                }
                attachToAdditionalProducts {
                  ... on WpProduct {
                    id
                  }
                }
                reviewRating
                reviewSource
                reviewText
              }
            }
          }
        }
      }
    `);
  } else {
    reviewsData.allWpReview.edges.push({
      node: {
        title: 'First-class service',
        customerReviews: {
          reviewDate: '10/01/2022',
          reviewProduct: {
            id: 'test'
          },
          reviewRating: 5,
          reviewSource: 'Chris - Site Manager - Hughes and Salvidge Ltd',
          reviewText: "SafeSite has supplied and fitted a camera unit covering the main site and motion sensors around the site's perimeter. All cameras are A. This has to lead to only genuine breaches being highlighted and reported. The feature that I have found to be very useful and set this system apart is instant access via phone or tablet, giving access to live footage. The team have been highly helpful and professional throughout and continue to offer a first-class service. I would recommend their services."
        }
      }
    })
  }

  return (
    <ReviewsContext.Provider value={reviewsData}>
      {children}
    </ReviewsContext.Provider>
  );
};

export default ReviewsProvider;