import React from "react";
import styled, { css } from "styled-components";

const GRID_COLUMNS = 12;

const setItemHeight = (equalHeight) => {
  if (!equalHeight) {
    return;
  }
  return css`
    > * > * {
      height: 100%;
    }
  `;
};

const setColumnGap = (columnGap) => {
  switch (columnGap) {
    case "loose":
      return 64;
    case "tight":
      return 16;
    case "none":
      return null;
    default:
      return 32;
  }
};

const StyledGridItem = styled.div`
  ${({ $columnGap, ...props }) => {
    let widths = `flex: 0 0 100%;`;

    Object.keys(props.theme.breakpoints).map((size) => {
      if (props[`$${size}`] && props.theme.breakpoints[size]) {
        widths += `
                    @media (min-width: ${props.theme.breakpoints[size]}) {
                        flex: 0 0 ${(props[`$${size}`] / GRID_COLUMNS) * 100}%;
                        flex-basis: auto; 
                        width: ${(props[`$${size}`] / GRID_COLUMNS) * 100}%
                    }
                `;
      }
      return null;
    });

    return css`
      flex-basis: 100%;
      width: 100%;
      position: relative;
      min-height: 1px;
      padding-right: 16px;
      padding-left: 16px;
      ${widths};

      @media (min-width: ${props.theme.breakpoints.md}) {
        padding-right: ${setColumnGap($columnGap) / 2}px;
        padding-left: ${setColumnGap($columnGap) / 2}px;
      }
    `;
  }};
`;

const StyledGridContainer = styled.div`
  ${({ ...props }) => css`
    display: flex;
    flex-flow: row wrap;
    margin-right: -16px;
    margin-left: -16px;
    margin-bottom: ${props.$rowGap ? `-${props.$rowGap}` : "-16px"};
    ${setItemHeight(props.$equalHeight)}

    > * {
      margin-bottom: ${props.$rowGap ? props.$rowGap : "24px"};
    }

    @media (min-width: ${props.theme.breakpoints.md}) {
      margin-right: -${setColumnGap(props.$columnGap) / 2}px;
      margin-left: -${setColumnGap(props.$columnGap) / 2}px;
      margin-bottom: ${props.$rowGap ? `-${props.$rowGap}` : "-32px"};
      flex-direction: ${props.$reverse ? `row-reverse` : `row`};

      > * {
        margin-bottom: ${props.$rowGap ? props.$rowGap : "32px"};
      }
    }
  `}
`;

export default function Grid({
  children,
  item,
  xs,
  sm,
  md,
  lg,
  xl,
  columnGap,
  rowGap,
  equalHeight,
  component = "div",
  reverse,
  ...rest
}) {
  if (item) {
    return (
      <StyledGridItem
        $xs={xs}
        $sm={sm}
        $md={md}
        $lg={lg}
        $xl={xl}
        $as={component}
        $columnGap={columnGap}
        {...rest}
      >
        {children}
      </StyledGridItem>
    );
  }
  return (
    <StyledGridContainer
      $columnGap={columnGap}
      $rowGap={rowGap}
      $equalHeight={equalHeight}
      $reverse={reverse}
      $as={component}
      {...rest}
    >
      {children}
    </StyledGridContainer>
  );
}
