import React, { createContext, useState } from "react";
import { isBrowser } from "../utils/isBrowser";

export const QuoteBasketContext = createContext(null);
QuoteBasketContext.displayName = "QuoteBasketContext";

export const QuoteBasketProvider = ({ children }) => {
  const storedQuoteBasketItems = isBrowser
    ? JSON.parse(sessionStorage.getItem("quoteBasketItems"))
    : [];
  const [quoteBasketItems, setQuoteBasketItems] = useState(
    storedQuoteBasketItems || []
  );
  const [quoteBasketIsActive, setQuoteBasketIsActive] = useState(false);

  return (
    <QuoteBasketContext.Provider
      value={{
        quoteBasketItems,
        setQuoteBasketItems,
        quoteBasketIsActive,
        setQuoteBasketIsActive,
      }}
    >
      {children}
    </QuoteBasketContext.Provider>
  );
};
