import React from "react";
import styled, { css } from "styled-components";

const ContainerStyles = styled.div`
  ${({ ...props }) => {
    let maxWidth = "1300px";

    if (props.$slim) {
      maxWidth = "800px";
    }

    return css`
      margin: 0 auto;
      max-width: ${maxWidth};
      padding: 0 16px;

      @media (min-width: ${props.theme.breakpoints.sm}) {
        padding: 0 24px;
      }

      @media (min-width: ${props.theme.breakpoints.md}) {
        padding: 0 48px;
      }
    `;
  }};
`;
export default function Container({ children, slim, ...rest }) {
  return (
    <ContainerStyles $slim={slim} {...rest}>
      {children}
    </ContainerStyles>
  );
}
