import React from "react";

function MenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      viewBox="0 0 21 18"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#343534" fillRule="nonzero">
          <path d="M.75 3.306h19.5c.414 0 .75-.329.75-.735V.735A.742.742 0 0020.25 0H.75A.742.742 0 000 .735V2.57c0 .406.336.735.75.735zm0 7.347h19.5c.414 0 .75-.329.75-.735V8.082a.742.742 0 00-.75-.735H.75a.742.742 0 00-.75.735v1.836c0 .406.336.735.75.735zM.75 18h19.5c.414 0 .75-.329.75-.735V15.43a.742.742 0 00-.75-.735H.75a.742.742 0 00-.75.735v1.836c0 .406.336.735.75.735z"></path>
        </g>
      </g>
    </svg>
  );
}

export default MenuIcon;
