import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled, { css } from "styled-components";
import { QuoteBasketContext } from "../context/QuoteBasketContext";
import BasketIcon from "./Basket";
import { convertToRelativePath } from "../utils/convertToRelativePath";
import SafeSiteLink from './SafeSiteLink';

const SecondaryNavStyles = styled.nav`
  ${({ ...props }) => css`
    @media (max-width: 991px) {
      margin-top: 24px;
      li {
        & + li {
          margin-top: 8px;
        }

        a {
          color: ${props.theme.colors.primary};
          display: block;
          letter-spacing: 0.5px;
          font-size: .75rem;
          text-transform: uppercase;
          font-weight: 700;
          transition: all 0.3s ease;
          width: 100%;
          text-align: left;
          padding: 0 12px;
          display: flex;
          line-height: 32px;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    @media (min-width: ${props.theme.breakpoints.lg}) {
      ul {
        display: flex;
        li {
          a {
            font-weight: 400;
            margin-right: 0;
            margin-left: 16px;
          }
        }
      }
    }
  `}
`;

const StyledBasketIcon = styled.button`
  ${({ ...props }) => css`
    background: none;
    padding: 0;
    line-height: 12px;
    color: ${props.theme.colors.darkGrey};
    margin-left: 24px;
    padding: 12px 0;

    svg {
      flex-shrink: 0;
      height: 24px;
    }

    &:hover,
    &:focus {
      background: #fff;
      outline: none;
      color: ${props.theme.colors.primary};
    }

    @media (max-width: 991px) {
      display: none;
    }
  `}
`;

export const SecondaryNavigation = ({ ...rest }) => {
  const { setQuoteBasketIsActive } = useContext(QuoteBasketContext);

  const data = useStaticQuery(graphql`
    query MenuQuery {
      wpMenu(name: { eq: "Header Secondary Navigation" }) {
        menuItems {
          nodes {
            label
            path
            id
          }
        }
      }
      wp {
        siteSettings {
          siteSettings {
            callToActionText
            telephone
            enableQuoteBasketSiteWide
          }
        }
      }
    }
  `);

  const basketIsEnabled =
    data.wp.siteSettings.siteSettings.enableQuoteBasketSiteWide;

  const handleOpenQuoteBasket = () => {
    setQuoteBasketIsActive(true);
  };

  data.wpMenu.menuItems.nodes.forEach(item => {
    item.path = convertToRelativePath(item.path)
  })

  return (
    <>
      <SecondaryNavStyles {...rest}>
        <ul>
          {data.wpMenu.menuItems.nodes.map((item) => {
            return (
              <li key={item.id}>
                <SafeSiteLink url={item.path} text={item.label} />
              </li>
            );
          })}

          {basketIsEnabled && (
            <li>
              <StyledBasketIcon onClick={() => handleOpenQuoteBasket()}>
                <BasketIcon />
              </StyledBasketIcon>
            </li>
          )}
        </ul>
      </SecondaryNavStyles>
    </>
  );
};
