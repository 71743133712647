import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import styled, { css } from "styled-components";
import ArrowDownIcon from "./Icons/ArrowDown";

const ProductNavigationStyles = styled.nav`
  ${({ ...props }) => css`
    @media (min-width: ${props.theme.breakpoints.lg}) {
      width: 100%;
      ul {
        display: flex;
        width: 100%;
        justify-content: space-between;

        li {
          position: relative;

          ul {
            display: none; // temp
            position: absolute;

            top: 100%;
            z-index: 9999;
            left: -16px;
            top: calc(100% + 4px);
            width: 300px;
            padding: 16px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            box-shadow: -1px 5px 12px -9px #474747;
            background: #fff;

            &.align-right {
              right: -16px;
              left: auto;
            }

            a {
              margin: 0;
              padding: 4px 8px;
              font-weight: 500;
              text-align: left;
              text-transform: unset;
              letter-spacing: 0;
              font-size: .875rem;
            }
          }

          &:hover {
            ul {
              display: block;
            }
          }
        }
      }
    }
  `}
`;

const SubNavStyles = styled.div`
  ${({ ...props }) => css`
    /* Mobile styles */
    @media (max-width: 991px) {
      display: ${(props.$visible ? "block" : "none")};
      background:  ${props.theme.colors.lightGrey};
      padding: 24px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      a {
        padding: 8px 0;
        display: block;
      }
    }
  `}
`;

const NavItemStyles = styled.li`
  ${({ ...props }) => {
    return css`
      /* Mobile styles */
      @media (max-width: 991px) {
        & + & {
          margin-top: 8px;
        }
        > a {
          color: #fff;
          display: block;
          background-color: ${props.theme.categoryColor};
          border-radius: 4px;
          color: #fff;
          letter-spacing: 0.5px;
          font-size: .75rem;
          text-transform: uppercase;
          font-weight: 700;
          transition: all 0.3s ease;
          width: 100%;
          text-align: left;
          padding: 0 24px;
          display: flex;
          line-height: 64px;
          justify-content: space-between;
          align-items: center;
          border-bottom-left-radius: ${(props) =>
            props.$visible ? "0" : "4px"};
          border-bottom-right-radius: ${(props) =>
            props.$visible ? "0" : "4px"};

          &:hover,
          &:focus {
            color: #fff;
          }

          svg {
            * {
              fill: #fff;
            }
          }
        }
      }

      /* Desktop styles */
      @media (min-width: ${props.theme.breakpoints.lg}) {
        svg {
          display: none;
        }

        border-bottom: solid 4px #fff;

        &:hover {
          border-bottom: solid 4px ${props.theme.categoryColor};

          > a {
            color: ${props.theme.categoryColor};
          }
        }

        ul a {
          border-radius: 4px;

          &:hover {
            background-color: ${props.theme.categoryColor};
            color: #fff;
          }
        }
      }
    `;
  }}
`;

const NavItem = (item) => {
  const [subNav, setSubNav] = useState(false);

  function showSubNav(e) {
    // stop page navigating on mobile
    if (window.innerWidth <= 992) {
      e.preventDefault();
    }
    setSubNav(!subNav);
  }

  function handleNavItemClick() {
    setSubNav(false);
  }

  return (
    <NavItemStyles
      $visible={subNav}
    >
      <Link
        to={item.item.path}
        partiallyActive={true}
        activeClassName="is-active"
        className="cat-active-border"
        onClick={item.item.children && showSubNav}
      >
        {item.item.title}
        <ArrowDownIcon />
      </Link>
      {item.item.children.length > 0 && (
        <SubNavStyles $visible={subNav}>
          <ul className={item.className}>
            {item.item.children.map((subItem, index) => {
              return (
                <li key={subItem.key}>
                  <Link to={subItem.path} onClick={handleNavItemClick}>
                    {subItem.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </SubNavStyles>
      )}
    </NavItemStyles>
  );
};

export const ProductNavigation = ({ ...rest }) => {
  const data = useStaticQuery(graphql`
    query ProductMenuQuery {
      wpMenu(name: { eq: "Header Product Navigation" }) {
        menuItems {
          nodes {
            key: id
            parentId
            title: label
            path
          }
        }
      }
    }
  `);

  const flatListToHierarchical = (
    data = [],
    { idKey = "key", parentKey = "parentId", childrenKey = "children" } = {}
  ) => {
    const tree = [];
    const childrenOf = {};
    data.forEach((item) => {
      const newItem = { ...item };
      const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
      childrenOf[id] = childrenOf[id] || [];
      newItem[childrenKey] = childrenOf[id];
      parentId
        ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
        : tree.push(newItem);
    });
    return tree;
  };

  const menuItems = data?.wpMenu?.menuItems?.nodes ?  flatListToHierarchical(data.wpMenu.menuItems.nodes) : [];

  return (
    <ProductNavigationStyles {...rest} className="product-nav">
      <ul>
        {menuItems.map((item, index) => {
          const count = index + 1;
          const midPoint = menuItems.length / 2;
          return (
            <NavItem
              item={item}
              className={count > midPoint ? "align-right" : ""}
              key={item.key}
            />
          );
        })}
      </ul>
    </ProductNavigationStyles>
  );
};
