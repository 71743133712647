import React from "react";
import styled, { css } from "styled-components";
import CallTrackLink from "./CalltrackLink";
import ContactTelephoneWrapper from "./ContactTelephoneWrapper";
import SearchInputForm from "./SearchInputForm";

const SearchFormStyles = styled.div`
  ${({ ...props }) => css`
    display: flex;
    position: relative;

    form {
      width: 100%;
      display: flex;

      label {
        display: block;
      }

      input {
        line-height: 48px;
        background: #fff;
        font-size: 1rem;
        border-radius: 4px;
        padding: 0 16px;
        min-height: 52px;
        border: solid 2px ${props.theme.colors.lightGrey};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
        transition: all ease-in-out 0.3s;
        width: 100%;

        &:focus,
        &:hover {
          outline: none;
          border: solid 2px ${props.theme.colors.primary};
        }
      }

      button {
        min-height: 52px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        display: flex;
        align-items: center;

        svg {
          pointer-events: none;
        }
      }
    }

    @media (min-width: ${props.theme.breakpoints.lg}) {
      form {
        width: auto;
        margin-left: 24px;

        input {
          width: 350px;
        }
      }
    }
  `}
`;

const SearchFormCTAStyles = styled.div`
  ${({ ...props }) => css`
    display: none;
    @media (min-width: ${props.theme.breakpoints.lg}) {
      text-align: right;
      display: block;

      p {
        font-size: 1rem;

        a {
          font-size: 2rem;
        }
      }
    }
  `}
`;

const CallCtaStyles = styled.p`
  ${({ ...props }) => css`
    @media (min-width: ${props.theme.breakpoints.lg}) {
      line-height: 0px;

      a {
        margin-top: 0px;
        display: inline-block;
        width: 240px;
        overflow: hidden;
        line-height: 32px;
        white-space: nowrap;

        span {
          line-height: 32px;
          display: inline-block;
        }
      }
    }
  `}
`;

export default function SearchInput({ title, searchAction }) {
  return (
    <SearchFormStyles>
      <SearchFormCTAStyles>
        <p>{title}</p>
        <ContactTelephoneWrapper>
          <CallCtaStyles>
            <strong>
              <CallTrackLink />
            </strong>
          </CallCtaStyles>
        </ContactTelephoneWrapper>
      </SearchFormCTAStyles>
      <SearchInputForm searchAction={searchAction} />
    </SearchFormStyles>
  );
}
