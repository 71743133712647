import React from "react";
import styled, { css } from "styled-components";

const StyledIcon = styled.svg`
  ${({ ...props }) => css`
    color: ${props.theme.colors.primary};
  `}
`;

function ArrowDownIcon() {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="currentColor"
          fillRule="nonzero"
          transform="rotate(-180 16 16)"
        >
          <g>
            <path
              d="M32 16c0-8.839-7.161-16-16-16S0 7.161 0 16s7.161 16 16 16 16-7.161 16-16zM16 29.935c-7.658 0-13.935-6.2-13.935-13.935 0-7.658 6.2-13.935 13.935-13.935 7.658 0 13.935 6.2 13.935 13.935 0 7.658-6.2 13.935-13.935 13.935zm-5.587-14.483l7.426-7.42a.775.775 0 011.096 0l.459.458a.775.775 0 010 1.097L12.968 16l6.42 6.42a.775.775 0 010 1.096l-.459.458a.775.775 0 01-1.097 0l-7.426-7.42a.789.789 0 01.007-1.102z"
              transform="rotate(90 16 16)"
            ></path>
          </g>
        </g>
      </g>
    </StyledIcon>
  );
}

export default ArrowDownIcon;
