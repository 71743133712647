import React from "react";

function SearchWhiteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" fillRule="nonzero">
          <path d="M23.79 22.531l-6.036-6.035a.555.555 0 00-.397-.164h-.482a9.688 9.688 0 002.587-6.601A9.73 9.73 0 009.731 0 9.73 9.73 0 000 9.731a9.73 9.73 0 0016.332 7.149v.477c0 .15.061.29.164.397l6.035 6.035c.22.22.575.22.795 0l.463-.463a.562.562 0 000-.795zM9.73 17.965A8.228 8.228 0 011.497 9.73a8.228 8.228 0 018.234-8.234 8.228 8.228 0 018.234 8.234 8.228 8.228 0 01-8.234 8.234z"></path>
        </g>
      </g>
    </svg>
  );
}

export default SearchWhiteIcon;
