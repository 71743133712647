import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#343534" fillRule="nonzero">
          <path d="M23.674 20.75L19 16.077a1.124 1.124 0 00-.797-.329h-.764A9.7 9.7 0 0019.5 9.75C19.501 4.364 15.137 0 9.751 0 4.364 0 0 4.364 0 9.749c0 5.385 4.364 9.75 9.75 9.75a9.704 9.704 0 006.001-2.063v.764c0 .3.117.586.328.797l4.674 4.673c.44.44 1.153.44 1.59 0l1.326-1.327c.44-.44.44-1.153.005-1.593zM9.75 15.748c-3.315 0-6-2.68-6-5.999 0-3.314 2.68-6 6-6 3.314 0 6 2.682 6 6 0 3.314-2.681 6-6 6z"></path>
        </g>
      </g>
    </svg>
  );
}

export default SearchIcon;
