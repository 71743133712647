import React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "styled-components";
import "@fontsource/poppins";
import "@fontsource/poppins/700.css";
import SafeSiteSeoContext from "../context/SafeSiteSeoContext";
import { theme } from "../styles/theme";
import { QuoteBasketProvider } from "../context/QuoteBasketContext";
import { GlobalStyle } from "../styles/GlobalStyle";
import ReviewsProvider from "../context/ReviewContext";

export default function Layout({ children }) {
  const {
    wp: { seo },
    site: { siteMetadata }
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
      wp {
        seo {
          contentTypes {
            post {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            inLanguage
          }
          social {
            facebook {
              defaultImage {
                mediaItemUrl
                altText
              }
            }
            pinterest {
              metaTag
            }
            twitter {
              username
              cardType
            }
          }
        }
      }
    }
  `);

  return (
    <ReviewsProvider>
      <QuoteBasketProvider>
        <ThemeProvider theme={theme}>
          <SafeSiteSeoContext.Provider value={{ global: { ...seo, siteUrl: siteMetadata.siteUrl } }}>
            <GlobalStyle />
            <Header />
            <main>{children}</main>
            <Footer />
          </SafeSiteSeoContext.Provider>
        </ThemeProvider>
      </QuoteBasketProvider>
    </ReviewsProvider>
  );
}
