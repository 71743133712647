import React from "react";
import { createGlobalStyle } from "styled-components";
import { theme } from './theme'

export const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: currentColor;
    border: none;
    background: none;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-size: .875rem;
    line-height: 20px;
    font-weight: 400;
    color: ${theme.colors.darkGrey};
    font-family: ${theme.fontFamily};

    &.body-no-scroll {
      overflow: hidden;
    }
  }

  img {
    display: block;
    width: 100%;
    
  }

  hr {
    height: 1px;
    border-top: 1px solid black;
  }

  ul,
  ol {
    list-style: none;
  }

  /* Links */
  a {
  text-decoration: none;
  color: currentColor;

    &:hover {
      color: ${theme.colors.darkPrimary};
    }
  }

  /* Buttons */
  button, .button {
    display: inline-block;
    line-height: 48px;
    font-size: 1rem;
    background: ${theme.colors.primary};
    padding: 0 16px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    letter-spacing: 0.5px;
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 0.3s ease;
    font-family: ${theme.fontFamily};

    &:hover {
      background: ${theme.colors.darkPrimary};
      color: #fff;
    } 

    &:focus {
      outline: none;
    }

    &--secondary {
      background: transparent;
      color: #343534;
      border: solid 2px;
      line-height: 32px;

      &:hover {
        background: #343534;
      } 
    }
  }
  .no-js {
    display: block;
  }
  button[type="submit"] {
    pointer-events: none;
    opacity: .75;
    filter: grayscale(1);
  }
  html.js-enabled {
    .no-js {
      display: none
    }
    button[type="submit"] {
      pointer-events: auto;
      opacity: 1;
      filter: none;
    }
  }



  /* Form elements */
  input,
  textarea, 
  select {
    line-height: 44px;
    background: #fff;
    font-size: 1rem;
    border-radius: 4px;
    padding: 0 16px;
    width: 100%;
    font-family: ${theme.fontFamily};
    border: solid 2px #fff;
    transition: all ease 0.3s;

    &:focus, &:hover {
      outline: none;
      border-color: ${theme.colors.primary};
    }
  }

  input[type=text] {   
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input[type="search"] {
  -webkit-appearance: none;
}

  select { 
    height: 48px;
    cursor: pointer;
    -webkit-appearance:none;
    background-color: white;
    /* inline SVG */
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
    background-position: right 16px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius:2px;
    /* border:none; */
    /* disable default appearance */
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    &::-ms-expand { display: none };
  }

  strong {
    font-weight: 700;
  }

  /* Headings */
  h1, h2, h3, h4, h5 {
    font-weight: 700;
  }

  h1 {
    font-size: 1.75rem;
    line-height: 32px;
    /* letter-spacing: -1px; */
  }

  h2 {
    font-size: 1.5rem;
    line-height: 32px;
    /* letter-spacing: -0.75px; */
  }

  h3 {
    font-size: 1.125rem;
    line-height: 24px;
  }

  blockquote {
    p {
      font-style: italic;
    }
    footer {
      margin-top: 16px;
      cite {
        font-style: normal;
      }
    }
  }

  @media (min-width: ${theme.breakpoints.md}) {
    h1 {
      font-size: 2.25rem;
      line-height: 48px;
    }

    h2 {
      font-size: 2rem;
      line-height: 44px;
    }
  }

  /* Accessability */
  .sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important; /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }

  /* Category colours */
  .cat-bg {
    background-color: ${theme.categoryColor}!important;
    color: #fff!important;
  }
  .cat-color, .lead {
    color: ${theme.categoryColor}!important;
  }
  .cat-border, .cat-active-border.is-active {
    border-color: ${theme.categoryColor}!important;
  }
  .cat-fill {
    fill: ${theme.categoryColor}!important;
  }
  &.cat-hover {
    &:hover {
      background-color: ${theme.categoryColor}!important;
      color:#fff;
    }
  }
  .sb-main-padded {
    padding: 0!important;
  }
`;