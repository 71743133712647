import React from "react";
import { Link } from "gatsby";
import { HtmlContent } from "./Content";

export default ({ url, text }) => {
  if (url && text) {
    return (
      <>
        {
          url.includes('https://') || url.includes('http://') ? 
          <a href={url}>
            <HtmlContent htmlString={text} />
          </a>
          :
          <Link to={url}>
            <HtmlContent htmlString={text} />
          </Link>
        }
      </>
    )
  }
  return (<></>)
}
