import React from "react";

function SisterSiteLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="230"
      height="69"
      viewBox="0 0 230 69"
    >
      <defs>
        <path
          id="path-1"
          d="M0.12325641 0.291923077L28.1298846 0.291923077 28.1298846 9.41820513 0.12325641 9.41820513z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <path
            fill="#0089C2"
            d="M10.08 21.415a9.254 9.254 0 012.468-.395h13.188v-7.834H11.59v.012a17.79 17.79 0 00-3.848.476c-1.395.326-2.66.872-3.801 1.64-1.14.767-2.081 1.766-2.824 2.999C.372 19.545 0 21.067 0 22.88c0 2.277.501 4.103 1.5 5.475a11.387 11.387 0 003.801 3.311 19.044 19.044 0 005.336 1.955c1.836.372 3.637.813 5.405 1.325.325.093.628.21.906.349.28.138.42.674.42 1.602-.095 1.117-.642 1.825-1.64 2.127-.915.28-1.882.411-2.894.433H0v7.9H14.787c2.092 0 3.835-.312 5.232-.94 1.393-.627 2.51-1.453 3.345-2.475.837-1.023 1.442-2.207 1.814-3.558.371-1.348.558-2.765.558-4.254 0-1.625-.256-2.987-.767-4.08-.512-1.09-1.187-1.998-2.023-2.72a9.374 9.374 0 00-2.824-1.674 31.646 31.646 0 00-3.174-1.011c-1.07-.233-2.103-.441-3.102-.627a17.837 17.837 0 01-2.686-.698c-.792-.232-1.395-.534-1.813-.906-.42-.372-.628-.883-.628-1.534 0-.698.452-1.186 1.36-1.466"
          ></path>
          <path
            fill="#0089C2"
            d="M50.286 37.84c-.977 1.047-2.698 1.57-5.161 1.57h-1.816c-.974 0-1.765-.059-2.37-.175-.605-.116-1.057-.28-1.359-.489a1.59 1.59 0 01-.628-.836 5.503 5.503 0 01-.245-1.15c.045-.42.116-.79.21-1.116.091-.326.28-.604.558-.837.28-.23.708-.441 1.29-.628.58-.185 1.407-.325 2.475-.42l8.579-.835c-.047 2.232-.56 3.87-1.533 4.916zm7.602-21.48c-1.721-2.06-4.641-3.11-8.742-3.164v-.01H29.989v7.889h17.482c.924.017 1.671.094 2.221.236.628.164 1.103.384 1.43.663.323.279.523.605.592.976.07.373.105.768.105 1.187v1.114l-10.323.907c-3.951.373-6.857 1.524-8.717 3.453-1.86 1.93-2.79 4.337-2.79 7.218 0 1.721.29 3.232.872 4.533a9.051 9.051 0 002.441 3.278c1.048.884 2.28 1.547 3.696 1.987 1.418.443 2.94.663 4.57.663h3.974c.978 0 2.221-.198 3.733-.593 1.51-.394 2.87-1.01 4.079-1.848l.977 1.883h6.206V25.67c0-4.09-.885-7.195-2.649-9.31z"
          ></path>
          <path
            fill="#0089C2"
            d="M78.845 10.257c.069-.558.241-1.012.52-1.36.28-.348.733-.592 1.361-.732.563-.125 1.328-.192 2.278-.204h4.801V.074H80.56v.033c-3.713.16-6.459 1.176-8.216 3.07-1.92 2.07-2.765 5.15-2.531 9.242l.07 1.116h-6.626v7.392h6.625v25.805h8.859V20.927h9.064v-7.392h-9.064v-1.186c0-.838.034-1.534.104-2.092"
          ></path>
          <path
            fill="#0089C2"
            d="M100.151 25.95c0-.978.022-1.779.07-2.406.046-.629.207-1.128.486-1.5.277-.372.729-.629 1.353-.768.623-.14 1.512-.21 2.671-.21h3.816c1.016 0 1.836.06 2.461.175.625.117 1.087.291 1.389.523.3.233.496.512.588.837a4.1 4.1 0 01.139 1.117c0 .37-.036.697-.104.975-.07.28-.255.524-.554.733-.302.21-.777.384-1.423.523-.648.14-1.527.279-2.636.419l-8.256.905V25.95zm2.058 13.25c-.627-.14-1.093-.384-1.395-.733-.303-.349-.487-.812-.558-1.393-.07-.581-.105-1.313-.105-2.198.882-.045 2.179-.114 3.892-.209 1.717-.09 3.827-.232 6.329-.417 1.622-.093 3.14-.385 4.553-.873 1.412-.488 2.642-1.174 3.684-2.058a9.178 9.178 0 002.433-3.208c.58-1.256.87-2.72.87-4.393 0-1.767-.29-3.314-.873-4.64-.58-1.324-1.394-2.416-2.438-3.276-1.046-.86-2.28-1.511-3.698-1.953-1.418-.442-2.965-.663-4.637-.663h-7.324c-4.28 0-7.3 1.058-9.067 3.174-1.767 2.115-2.652 5.22-2.652 9.31v9.137c0 4.045.903 7.138 2.708 9.275 1.763 2.093 4.728 3.154 8.875 3.2v.008h19.106v-7.892h-17.67c-.84-.02-1.521-.085-2.033-.197z"
          ></path>
          <path
            fill="#4D4E4C"
            d="M157.745795 46.4187179L166.533859 46.4187179 166.533859 13.1503974 157.745795 13.1503974z"
          ></path>
          <path
            fill="#4D4E4C"
            d="M137.835 21.38a9.293 9.293 0 012.47-.396h13.187V13.15h-14.144v.013a17.88 17.88 0 00-3.85.476c-1.391.327-2.66.873-3.8 1.64-1.141.767-2.08 1.766-2.825 2.998-.741 1.232-1.115 2.755-1.115 4.569 0 2.277.5 4.104 1.498 5.475a11.38 11.38 0 003.803 3.312 19.121 19.121 0 005.336 1.954c1.835.37 3.638.814 5.403 1.326.327.092.628.207.908.348.278.139.418.672.418 1.602-.091 1.117-.641 1.825-1.64 2.127-.914.278-1.882.412-2.894.433h-12.832v7.901H142.545c2.09 0 3.835-.314 5.23-.94 1.395-.629 2.512-1.453 3.346-2.476.838-1.022 1.443-2.207 1.814-3.558.372-1.347.557-2.764.557-4.253 0-1.627-.256-2.988-.766-4.08-.512-1.09-1.186-1.999-2.023-2.72a9.331 9.331 0 00-2.824-1.673 31.536 31.536 0 00-3.173-1.012c-1.07-.232-2.105-.441-3.104-.628-1-.185-1.894-.419-2.686-.698-.791-.233-1.395-.534-1.812-.905-.42-.373-.629-.884-.629-1.535 0-.698.453-1.186 1.36-1.465"
          ></path>
          <path
            fill="#4D4E4C"
            d="M208.017 25.984c0-.977.022-1.778.067-2.405.047-.629.21-1.128.486-1.5.276-.372.729-.629 1.354-.769.625-.139 1.514-.208 2.67-.208h3.817c1.017 0 1.837.06 2.463.174.621.117 1.087.291 1.385.523.301.233.498.512.59.837.091.327.139.698.139 1.117 0 .37-.035.697-.104.975-.069.279-.254.523-.556.733-.298.209-.774.383-1.42.523-.647.139-1.527.279-2.636.419l-8.255.907v-1.326zm2.056 13.251c-.629-.14-1.092-.384-1.393-.734-.305-.348-.489-.812-.558-1.393-.07-.58-.105-1.313-.105-2.197.88-.045 2.177-.115 3.892-.209 1.715-.09 3.825-.232 6.327-.418a16.85 16.85 0 004.555-.873c1.412-.487 2.64-1.173 3.682-2.058a9.207 9.207 0 002.434-3.206c.581-1.256.87-2.721.87-4.394 0-1.767-.292-3.314-.873-4.64-.582-1.324-1.394-2.416-2.44-3.276-1.047-.86-2.278-1.512-3.697-1.954-1.42-.441-2.964-.662-4.638-.662h-7.324c-4.277 0-7.3 1.058-9.068 3.174-1.765 2.115-2.648 5.22-2.648 9.31v9.137c0 4.045.902 7.137 2.706 9.275 1.764 2.092 4.726 3.154 8.873 3.2v.008h19.11v-7.892h-17.674c-.836-.02-1.517-.085-2.031-.198z"
          ></path>
          <path
            fill="#4D4E4C"
            d="M194.835 20.927v-7.392h-8.021V3.7h-8.718v9.835h-7.253v7.392h7.253v13.88c0 4.045.883 7.138 2.652 9.275 1.763 2.14 4.786 3.208 9.063 3.208.06 0 .108-.003.166-.005v.005h4.858v-7.885h-3.479c-1.061-.006-1.896-.074-2.486-.204-.625-.141-1.092-.395-1.393-.769-.304-.372-.487-.87-.558-1.5-.07-.626-.105-1.427-.105-2.404v-13.6h8.021"
          ></path>
          <path
            fill="#4D4E4C"
            d="M76.4617308 61.4212051L71.4365256 61.4212051 71.4365256 63.834141 75.8958718 63.834141 75.8958718 65.2465769 71.4365256 65.2465769 71.4365256 68.8469615 69.8822564 68.8469615 69.8822564 60.0058205 76.4617308 60.0058205 76.4617308 61.4212051"
          ></path>
          <g transform="translate(86.397 59.564)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <path
              fill="#4D4E4C"
              d="M4.71 2.236l-1.517 3.54h3.045L4.71 2.236zm4.634 7.047H7.7l-.895-2.135H2.625l-.91 2.135H.123L4.016.376h1.437l3.89 8.907z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#4D4E4C"
              d="M24.543 9.436c-2.576 0-4.497-2.01-4.497-4.56 0-2.523 1.883-4.584 4.561-4.584 1.63 0 2.613.568 3.461 1.373l-.998 1.152c-.708-.654-1.465-1.097-2.479-1.097-1.69 0-2.914 1.386-2.914 3.13 0 1.746 1.225 3.158 2.914 3.158 1.089 0 1.784-.443 2.542-1.15l.997 1.013c-.921.96-1.93 1.565-3.587 1.565"
              mask="url(#mask-2)"
            ></path>
          </g>
          <path
            fill="#4D4E4C"
            d="M126.369667 68.8469615L127.922167 68.8469615 127.922167 60.0058205 126.369667 60.0058205z"
          ></path>
          <path
            fill="#4D4E4C"
            d="M140.4035 60.0058205L141.956 60.0058205 141.956 67.4351154 146.607603 67.4351154 146.607603 68.8469615 140.4035 68.8469615 140.4035 60.0058205"
          ></path>
          <path
            fill="#4D4E4C"
            d="M158.434026 68.8469615L159.985346 68.8469615 159.985346 60.0058205 158.434026 60.0058205z"
          ></path>
          <path
            fill="#4D4E4C"
            d="M176.205949 68.8469615L174.639295 68.8469615 174.639295 61.4459744 171.834769 61.4459744 171.834769 60.0058205 179.00841 60.0058205 179.00841 61.4459744 176.205949 61.4459744 176.205949 68.8469615"
          ></path>
          <path
            fill="#4D4E4C"
            d="M190.86491 68.8469615L192.415641 68.8469615 192.415641 60.0058205 190.86491 60.0058205z"
          ></path>
          <path
            fill="#4D4E4C"
            d="M211.453449 61.396141L206.451244 61.396141 206.451244 63.6937821 210.882872 63.6937821 210.882872 65.0843974 206.451244 65.0843974 206.451244 67.4598846 211.515667 67.4598846 211.515667 68.8469615 204.895205 68.8469615 204.895205 60.0058205 211.453449 60.0058205 211.453449 61.396141"
          ></path>
          <path
            fill="#4D4E4C"
            d="M226.895 63.722c1.869.452 2.843 1.122 2.843 2.601 0 1.667-1.303 2.655-3.16 2.655a5.462 5.462 0 01-3.69-1.418l.934-1.11c.848.734 1.695 1.148 2.797 1.148.955 0 1.563-.444 1.563-1.123 0-.646-.352-.989-1.995-1.366-1.883-.455-2.945-1.013-2.945-2.652 0-1.538 1.265-2.576 3.02-2.576 1.287 0 2.31.39 3.21 1.11l-.835 1.177c-.798-.594-1.593-.91-2.402-.91-.908 0-1.435.469-1.435 1.061 0 .695.402.998 2.095 1.403"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SisterSiteLogo;
