import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useHasMounted } from "../hooks/useHasMounted";

const ContactStyles = styled.div`
  ${({ ...props }) => css`
    transition: all ease 0.3s;
    opacity: ${(props.$visible ? "1" : "0")};
  `}
`;

export default function ContactTelephoneWrapper({ children }) {
  const [visibleState, setVisibleState] = useState(false); // THIS SHOULD BE SET TO FALSE BY DEFAULT
  const hasMounted =  useHasMounted();
  useEffect(() => {
    var dt = new Date();

    if (dt.getDay() === 6 || dt.getDay() === 0) {
      setVisibleState(false);
      return;
    }

    if (window.__ctwnd) {
      window.__ctwnd.reinit();
    }
  
    setVisibleState(true);
  });

  return <ContactStyles suppressHydrationWarning $visible={hasMounted && visibleState}>{children}</ContactStyles>;
}
