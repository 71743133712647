import React from "react";
import styled, { css } from "styled-components";

const FormWrapperStyles = styled.div`
  ${({ ...props }) => css`
    background-color: ${props.theme.colors.lightGrey};
    padding: 24px;
    border-radius: 4px;
    position: sticky;
    top: calc(32px + 56px);

    h2 {
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 24px;
    }

    @media (min-width: ${props.theme.breakpoints.md}) {
      padding: 32px;
    }
  `}
`;

const ConfirmationStyles = styled.div`
  ${({ $hasError }) => css`
    margin-top: 24px;
    background: #52864e;
    border-radius: 4px;
    padding: 16px;
    color: white;
    ${$hasError && css`
      background-color: #bd0000;
    `}
  `}
`;

const FormFieldStyles = styled.div`
  ${({ ...props }) => css`
    margin-bottom: 16px;
    position: relative;

    &:last-child {
      margin: 0;
    }

    input,
    textarea {
      padding: 24px 16px 8px 16px;
      line-height: 24px;
    }

    input,
    textarea {
      + label {
        pointer-events: none
      }
      &:focus + label,
      &:not(:placeholder-shown) + label {
        font-size: .75rem;
        line-height: 16px;
        top: 8px;
      }

      &:invalid:not(:placeholder-shown) {
        border-color: red;
      }

      &:focus::-webkit-input-placeholder {
        opacity: 1;
      }

      &::-webkit-input-placeholder {
        opacity: 0;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #fff;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #fff;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #fff;
      }
    }

    textarea {
      min-height: 136px;
      resize: vertical;
    }

    label {
      position: absolute;
      top: 0px;
      left: 2px;
      font-size: 1rem;
      padding: 0 16px;
      line-height: 60px;
      transition: all ease 0.3s
    }
  `}
`;

export const FormField = ({ children }) => {
  return <FormFieldStyles>{children}</FormFieldStyles>;
};

export const FormWrapper = ({ children }) => {
  return <FormWrapperStyles>{children}</FormWrapperStyles>;
};

export const Confirmation = ({ children, hasError }) => {
  return <ConfirmationStyles $hasError={hasError}>{children}</ConfirmationStyles>;
};
